.__modal__ {
	--bs-modal-width: 800px;
	max-width: 98%;
}

.__modal_dialog__ .modal-dialog {
	max-width: 90vw; /* Largeur maximale de 80% de la vue */
	max-height: 90vh; /* Hauteur maximale de 80% de la vue */
	margin: auto;
}

.__modal_dialog__ .modal-content {
	background-color: transparent; /* Rendre le fond transparent */
	border: none; /* Supprimer la bordure */
	height: 100%; /* Utiliser toute la hauteur disponible */
}

.__modal_dialog__ .modal-body {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	height: 100%; /* Assurer que le body utilise toute la hauteur de la modal */
}

/* Boutons de navigation flottants */
.__modal_dialog__ .position-absolute button {
	background-color: rgba(255, 255, 255, 0.7); /* Boutons semi-transparents */
	border: none;
}