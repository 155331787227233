.__admin_header__ {
	height: 55px;
	background-color: $blue-ctc;
	border-bottom: solid 1px $yellow-ctc;
}

.__admin_header__ a {
	color: $yellow-ctc
}

.__admin_header_action__ {
	font-size: 1.1em;
}

.__admin_header_action__ i {
	font-size: 1.3em;
	color: $yellow-ctc;
}

.__admin_sidebar__ {
	background-color: $blue-ctc;
	min-height: calc(100vh - 55px);
}

.__admin_sidebar_menu__ {
	border-bottom: solid 1px $yellow-ctc;
	color: $yellow-ctc;
}

.__admin_sidebar_menu__ a {
	text-decoration: none;
	font-size: 1.1em;
	font-weight: bold;
	padding: 0.8em;
	display: block;
	width: 100%;
	height: 100%;
	color: $yellow-ctc;
}

.__admin_sidebar_menu__ a:hover, .__admin_sidebar_menu__.__admin_sidebar_menu_hover__ a.active,
.__admin_sidebar_menu_submenu__ a:hover, .__admin_sidebar_menu_submenu__ a.active
{
	background-color: $orange-ctc;
}

.__admin_sidebar_menu_submenu__ {
}
.__admin_sidebar_menu_submenu__ a {
	font-size: 1em;
	font-weight: normal;
	padding: 0.4em 0.4em 0.4em 1.5em;
	display: block;
	width: 100%;
	height: 100%;
	color: $yellow-ctc;
}

.__admin_sidebar_menu_submenu__ i {
	font-size: 0.8em;
}

.__admin_office_card:hover {
	border-color: $primary;
}