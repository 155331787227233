.__filtre_btn__, .__filtre_btn__:hover {
	background-color: $gray-300;
	border-color: $gray-600;
}

.__filtre_btn__:hover {
	border-color: $orange-ctc;
}

.__filtre_btn__.__filtre_actif__ {
	background-color: $orange-ctc;
	color: $gray-200;
}
