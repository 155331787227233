@for $i from 1 through 200 {
	.w#{$i * 10}px {
		width: ($i * 10px) !important;
	}
	.max-w#{$i * 10}px {
		max-width: ($i * 10px) !important;
	}
	.min-w#{$i * 10}px {
		min-width: ($i * 10px) !important;
	}

	.h#{$i * 10}px {
		height: ($i * 10px) !important;
	}
	.max-h#{$i * 10}px {
		max-height: ($i * 10px) !important;
	}
	.min-h#{$i * 10}px {
		min-height: ($i * 10px) !important;
	}
}

@for $i from 1 through 20 {
	.w#{$i * 10} {
		width: ($i * 10%) !important;
	}
	.max-w#{$i * 10} {
		max-width: ($i * 10%) !important;
	}
	.min-w#{$i * 10} {
		min-width: ($i * 10%) !important;
	}

	.h#{$i * 10} {
		height: ($i * 10%) !important;
	}
	.max-h#{$i * 10} {
		max-height: ($i * 10%) !important;
	}
	.min-h#{$i * 10} {
		min-height: ($i * 10%) !important;
	}
}