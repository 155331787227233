.__front_reglement_titre__ {
	font-size: 16px;
	font-weight: bold;
}

.__front_reglement_block__ {
	margin-bottom: 33px;
}

.__front_reglement_line__ {
	font-size: 14px;
}

.__front_reglement_line__ > .text-danger {
	color: red;
}

.badge.bg-primary {
	color: #fff;
	background-color: #337ab7;
	font-size: 9px;
}