@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url(https://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxh_xHqYgAV9Bl_ZQbYUxnQU.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBobN6UDyHWBl620a-IRfuBk.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'), url(https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxn5HxGBcBvicCpTp6spHfNo.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxjqR_3kx9_hJXbbyU8S6IN0.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'), url(https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxkCDe67GEgBv_HnyvHTfdew.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 100;
  src: local('Josefin Slab Thin'), local('JosefinSlab-Thin'), url(https://fonts.gstatic.com/s/josefinslab/v6/etsUjZYO8lTLU85lDhZwUi1dfeF-hbOdKKuBDEXxqhQ.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 300;
  src: local('Josefin Slab Light'), local('JosefinSlab-Light'), url(https://fonts.gstatic.com/s/josefinslab/v6/NbE6ykYuM2IyEwxQxOIi2GFp2sMiApZm5Dx7NpSTOZk.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Josefin Slab'), local('JosefinSlab'), url(https://fonts.gstatic.com/s/josefinslab/v6/46aYWdgz-1oFX11flmyEfXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 600;
  src: local('Josefin Slab SemiBold'), local('JosefinSlab-SemiBold'), url(https://fonts.gstatic.com/s/josefinslab/v6/NbE6ykYuM2IyEwxQxOIi2NOd4RnLmtUrdSQkDmC5b8k.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 700;
  src: local('Josefin Slab Bold'), local('JosefinSlab-Bold'), url(https://fonts.gstatic.com/s/josefinslab/v6/NbE6ykYuM2IyEwxQxOIi2Hl4twXkwp3_u9ZoePkT564.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: italic;
  font-weight: 100;
  src: local('Josefin Slab Thin Italic'), local('JosefinSlab-ThinItalic'), url(https://fonts.gstatic.com/s/josefinslab/v6/8BjDChqLgBF3RJKfwHIYh6EyK9Hr0SO5vc2-ZCW-Yes.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: italic;
  font-weight: 300;
  src: local('Josefin Slab Light Italic'), local('JosefinSlab-LightItalic'), url(https://fonts.gstatic.com/s/josefinslab/v6/af9sBoKGPbGO0r21xJuly20Xvi9kvVpeKmlONF1xhUs.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: italic;
  font-weight: 400;
  src: local('Josefin Slab Italic'), local('JosefinSlab-Italic'), url(https://fonts.gstatic.com/s/josefinslab/v6/etsUjZYO8lTLU85lDhZwUnpumDtkw9GHrrDfd7ZnWpU.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: italic;
  font-weight: 600;
  src: local('Josefin Slab SemiBold Italic'), local('JosefinSlab-SemiBoldItalic'), url(https://fonts.gstatic.com/s/josefinslab/v6/af9sBoKGPbGO0r21xJuly6xza3rXaV_G32gF04a66fI.woff) format('woff');
}
@font-face {
  font-family: 'Josefin Slab';
  font-style: italic;
  font-weight: 700;
  src: local('Josefin Slab Bold Italic'), local('JosefinSlab-BoldItalic'), url(https://fonts.gstatic.com/s/josefinslab/v6/af9sBoKGPbGO0r21xJuly70qgHI2SEqiJszC-CVc3gY.woff) format('woff');
}
