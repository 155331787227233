// region Background
.__front_background__ {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: url('../images/bg.jpg') no-repeat center center fixed;
	background-size: cover;
}
// endregion

// region Header
.__header_nav__ {
	position: sticky;
	top: 0;
	z-index: 10;
}
.__front_h1__, .__front_h2__ {
	width: 100%;
	font-family: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
	margin: 0;
}
.__front_h1__ {
	text-align: center;
	font-size: 4em;
	padding: 30px 0 10px;
}
.__front_h2__ {
	font-size: 2em;
	padding: 10px;
}
.__front_h1__ > a, .__front_h2__ > a {
	text-shadow: 10px 10px 10px #000;
	color: $yellow-ctc;
	text-decoration: none;
	font-weight: bold;
}
.__front_h2_btn__, .__front_h2_btn__:hover {
	border-color: $yellow-ctc;
	color: $yellow-ctc;
}
.__front_h2_btn__:hover {
	border-color: $orange-ctc;
}
.__front_navbar__ > a {
	color: $yellow-ctc;
	text-decoration: none;
	text-transform: uppercase;
	padding: 12px 15px;
	font-size: 15px;
	font-weight: bold;
}
.__front_navbar__ > a:hover {
	color: $orange-ctc;
}
.__front_navbar__ > a.active {
	background-color: $orange-ctc;
}
.__front_navbar__ > a.active:hover {
	color: $yellow-ctc;
}
// endregion

// region Footer
footer {
	font-size: 15px;
	background: rgba(255, 255, 255, 0.9);
}

footer a {
	text-decoration: none;
}

footer a:hover {
	text-decoration: underline;
}
//endregion

// region Global
body {
	font-size: 14px;
}
#root {
	margin: 0;
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto auto 1fr auto;
}

.container {
	max-width: 1170px;
}

a {
	color: $primary;
}

.c-team-chartres {
	color: $orange-ctc;
	font-weight: bold;
}

.__front_box__ {
	background-color: #FFF;
	border: 1px solid rgba(155, 155, 155, 0.8);
	box-shadow: 5px 5px 5px #999;
	padding: 20px;
}

.__box_title_container__ {
	width: 100%;
	margin-bottom: 20px;
}
.__box_title_container__ > hr {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	padding: 0;
	border-top: 1px solid $blue-ctc;
	opacity: 1;
}
.__box_title_h2__ {
	text-align: center;
	text-transform: uppercase;
	font-size: 1.25em;
	font-weight: 400;
	letter-spacing: 1px;
	color: $blue-ctc;
	margin: 20px;
	padding: 0;
}
// endregion

//region Home
//endregion

// region Info
.__front_info_title__ {
	font-size: 1.1em;
	text-align: center;
	color: $gray-600;
}
.__front_info_text__ {
	text-align: justify;
}
.__front_info_photo__ {
	max-height: 150px;
}
//endregion

// region Office
.__front_office_name__ {
	color: $orange-ctc;
	text-transform: uppercase;
	text-decoration: underline;
}
// endregion

//region Modal
.__modal_container__, .__modal_background__ {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
}
.__modal_background__ {
	background-color: #0b0b0b;
	opacity: 0.7;
}
.__modal_box__ {
	z-index: 55;
	overflow: auto;
	max-height: 95%;
	max-width: 95%;
	text-align: justify;
}
.__modal_box__ > i {
	font-size: 1.5em;
}
//endregion

//region Album
.__front_album__:hover,
.__front_album_selected__ {
	background-color: $orange-ctc;
	border-color: $orange-ctc !important;
}
.__front_album__:hover > div,
.__front_album_selected__ > div {
	border-color: $orange-ctc !important;
	background-color: $white;
}
a.__front_album_select__ {
	color: $dark;
	text-decoration: none;
}
// endregion