.loader {
	border: solid 3px $blue-ctc;
	border-top: $orange-ctc;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	animation: spin 1s linear infinite;
	margin: auto;
}

/* Animation pour faire tourner le loader */
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}