.__form-login__ {
	display: flex; /* Utilisation de Flexbox */
	justify-content: center; /* Centre horizontalement */
	align-items: center; /* Centre verticalement */
	height: 100vh; /* Définit la hauteur du viewport (écran) */
	margin: 0; /* Supprime les marges par défaut du body */
}

.__form-login_container__ {
	width: 400px;
	background-color: $light;
}

.__form-login_lock__ {
	font-size: 5em;
	color: $gray-ctc;
}

.__form-login_button__ > button, .__form-login_button__ > a {
	width: 100%;
	border-radius: 0;
}